<template>
  <v-navigation-drawer
    v-model="getThemeMode.verticalSidebarDrawer"
    class="sidebar-six"
    dark
    height="100%"
    width="280px"
    :mobile-breakpoint="960"
    :floating="true"
    app
  >
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      class="ps"
      style="height: 100%"
    >
      <div class="mb-4 pa-4 top-toolbar">
        <v-avatar size="36">
          <v-img src="@/assets/images/optimy-logo.png"></v-img>
        </v-avatar>

        <span class="subtitle-1 mb-0 text-capitalize white--text"
          >&nbsp;&nbsp;&nbsp;Optimy POS</span
        >
      </div>
      <!-- <v-menu v-model="showMenu" absolute offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="
              pa-3
              rounded-lg
              mx-4
              white--text
              bg-sidebar-six
              d-flex
              space-between
            "
          >
            <div class="flex-1">
              <p class="subtitle-1 mb-0 text-capitalize">Octavia Inc</p>
              <p class="text-subtitle-2 mb-0 grey--text text--lighten-1">
                Your tier: Premium
              </p>
            </div>
            <v-icon right>mdi-chevron-down</v-icon>
          </div>
        </template>

        <v-list color="dark text--white">
          <v-list-item dark v-for="(item, index) in dropdownItem" :key="index">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->

      <!-- <v-divider class="my-2"></v-divider> -->

      <v-list class="mt-n12 mx-4" dense>
        <template v-for="(item, i) in allowLiveData == 0? computedItems : computedItemsWithLiveData">
          <div :key="item.subheader" v-if="item.subheader">
            <v-subheader>
              {{ item.subheader }}
            </v-subheader>

            <v-divider></v-divider>
          </div>

          <base-item-group-two
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
            <!--  -->
          </base-item-group-two>

          <base-item-two
            v-else-if="!item.subheader"
            :key="`item-${i}`"
            :item="item"
          />
        </template>
      </v-list>
      <!-- <v-divider></v-divider> -->
      <!-- <div class="mx-6 my-4 white--text">
        <p class="subtitle-1 mb-0 text-capitalize">Octavia Inc</p>
        <p class="text-subtitle-2 mb-0 grey--text">Your tier: Premium</p>
      </div>
      <div class="px-6 mb-6">
        <v-btn block class="text-capitalize" color="primary">
          Documentation
        </v-btn>
      </div> -->
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>
<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      domain: BASEURL,
      selectedItem: 0,
      group: null,
      dropdownItem: [{ title: "Octavia Inc" }, { title: "Egret Inc" }],
      showMenu: false,
      companyID: "",
      allowLiveData: 0,
    };
  },
  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.checkBranchAllowLiveData(this.companyID)
  },

  computed: {
    ...mapGetters(["getThemeMode"]),
    bg() {
      return this.background
        ? "https://images.pexels.com/photos/1687678/pexels-photo-1687678.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
        : undefined;
    },
    getMiniVariant() {
      return this.miniVariant;
    },
    computedItemsWithLiveData() {
      return [
        {
          divider: this.$t("sideBar.dashboard"),
          title: this.$t("sideBar.report"),
          icon: "mdi-chart-box",
          group: "report",
          children: [
            {
              title: "Live Sales",
              to: "live-sales",
              icon: "mdi-circle-medium",
            },
            {
              title: this.$t("sideBar.salesSummary"),
              to: "sale-summary",
              icon: "mdi-circle-medium",
            },

            {
              title: this.$t("sideBar.dailySales"),
              to: "sale-per-day",
              icon: "mdi-circle-medium",
            },

            {
              title: this.$t("sideBar.diningSales"),
              to: "sale-dining-per-day",
              icon: "mdi-circle-medium",
            },

            {
              title: this.$t("sideBar.productSales"),
              to: "sale-product-per-day",
              icon: "mdi-circle-medium",
            },

            {
              title: this.$t("sideBar.productStock"),
              to: "product-stock-report",
              icon: "mdi-circle-medium",
            },

            // {
            //   title: "Hourly Product Sales",
            //   to: "sale-product-per-hour",
            //   icon: "mdi-circle-medium",
            // },

            {
              title: this.$t("sideBar.categorySales"),
              to: "sale-category-per-day",
              icon: "mdi-circle-medium",
            },

            {
              title: this.$t("sideBar.modifierSales"),
              to: "sale-modifier-per-day",
              icon: "mdi-circle-medium",
            },

            {
              title: "Staff Attendence",
              to: "staff-attendence",
              icon: "mdi-circle-medium",
            },

            {
              title: "Cancellation",
              to: "sale-cancellation",
              icon: "mdi-circle-medium",
            },
            {
              title: "Refund",
              to: "sale-refund",
              icon: "mdi-circle-medium",
            },
          ],
        },

        {
          // divider: "Dashboard",
          title: this.$t("sideBar.products"),
          icon: "mdi-package-variant",
          group: "products",
          children: [
            {
              title: this.$t("sideBar.productsList"),
              to: "productList",
              icon: "mdi-circle-medium",
            },
            {
              title: this.$t("sideBar.categories"),
              icon: "mdi-circle-medium",
              to: "categories",
              icon: "mdi-circle-medium",
            },
            {
              title: this.$t("sideBar.modifiers"),
              icon: "mdi-circle-medium",
              to: "modifier",
              icon: "mdi-circle-medium",
            },
            {
              title: this.$t("sideBar.branchModifiers"),
              icon: "mdi-circle-medium",
              to: "branch-modifier",
              icon: "mdi-circle-medium",
            },
          ],
        },

        {
          title: this.$t("sideBar.staff"),
          icon: "mdi-account-group",
          group: "staff",
          children: [
            {
              title: this.$t("sideBar.staffList"),
              to: "staffList",
              icon: "mdi-circle-medium",
            },
          ],
        },

        {
          title: this.$t("sideBar.customer"),
          icon: "mdi-account-details",
          to: "customer",
        },

        {
          title: this.$t("sideBar.setting"),
          icon: "mdi-cog",
          to: "setting",
        },
      ];
    },
    computedItems() {
      return [
        {
          divider: this.$t("sideBar.dashboard"),
          title: this.$t("sideBar.report"),
          icon: "mdi-chart-box",
          group: "report",
          children: [
            {
              title: this.$t("sideBar.salesSummary"),
              to: "sale-summary",
              icon: "mdi-circle-medium",
            },

            {
              title: this.$t("sideBar.dailySales"),
              to: "sale-per-day",
              icon: "mdi-circle-medium",
            },

            {
              title: this.$t("sideBar.diningSales"),
              to: "sale-dining-per-day",
              icon: "mdi-circle-medium",
            },

            {
              title: this.$t("sideBar.productSales"),
              to: "sale-product-per-day",
              icon: "mdi-circle-medium",
            },

            {
              title: this.$t("sideBar.productStock"),
              to: "product-stock-report",
              icon: "mdi-circle-medium",
            },

            // {
            //   title: "Hourly Product Sales",
            //   to: "sale-product-per-hour",
            //   icon: "mdi-circle-medium",
            // },

            {
              title: this.$t("sideBar.categorySales"),
              to: "sale-category-per-day",
              icon: "mdi-circle-medium",
            },

            {
              title: this.$t("sideBar.modifierSales"),
              to: "sale-modifier-per-day",
              icon: "mdi-circle-medium",
            },

            {
              title: "Staff Attendence",
              to: "staff-attendence",
              icon: "mdi-circle-medium",
            },
            {
              title: "Cancellation",
              to: "sale-cancellation",
              icon: "mdi-circle-medium",
            },
            {
              title: "Refund",
              to: "sale-refund",
              icon: "mdi-circle-medium",
            },
          ],
        },

        {
          // divider: "Dashboard",
          title: this.$t("sideBar.products"),
          icon: "mdi-package-variant",
          group: "products",
          children: [
            {
              title: this.$t("sideBar.productsList"),
              to: "productList",
              icon: "mdi-circle-medium",
            },
            {
              title: this.$t("sideBar.categories"),
              icon: "mdi-circle-medium",
              to: "categories",
              icon: "mdi-circle-medium",
            },
            {
              title: this.$t("sideBar.modifiers"),
              icon: "mdi-circle-medium",
              to: "modifier",
              icon: "mdi-circle-medium",
            },
            {
              title: this.$t("sideBar.branchModifiers"),
              icon: "mdi-circle-medium",
              to: "branch-modifier",
              icon: "mdi-circle-medium",
            },
          ],
        },

        {
          title: this.$t("sideBar.staff"),
          icon: "mdi-account-group",
          group: "staff",
          children: [
            {
              title: this.$t("sideBar.staffList"),
              to: "staffList",
              icon: "mdi-circle-medium",
            },
          ],
        },

        {
          title: this.$t("sideBar.customer"),
          icon: "mdi-account-details",
          to: "customer",
        },

        {
          title: this.$t("sideBar.setting"),
          icon: "mdi-cog",
          to: "setting",
        },
      ];
    },

  },
  methods: {
    ...mapActions(["changeVerticalSidebarDrawer"]),
    toggleSidebar() {
      this.changeVerticalSidebarMini();
      this.expandOnHover = !this.expandOnHover;
      // this.$emit("update:mini-variant");
      // console.log("check");
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
        // to: !item.to ? undefined : `/app/${this.item.group}/${item.to}`,
      };
    },
    checkBranchAllowLiveData(companyID) {
      const params = new URLSearchParams();
      params.append("getCompanyBranchWithLiveData", "done");
      params.append("company_id", companyID);
      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          if (response.data.status == "1") {
            this.allowLiveData = 1
          } else {
            this.allowLiveData = 0
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss">
.bg-sidebar-six {
  background-color: #292f3d;
}
.theme--dark .v-navigation-drawer {
  .bg-sidebar-six {
    background-color: #3a3939;
  }
}
.sidebar-six {
  .v-list-item__icon {
    margin-right: 8px !important;
    // margin-bottom: 0;
    // margin-top: 0;
  }

  .v-list-item--link:before {
    border-radius: 8px;
  }
}
</style>
