<template>
    <v-row>
      <v-col cols="12">
          <sale-cancellation-card> </sale-cancellation-card> 
      </v-col>
    </v-row>
  </template>
  <script>
  import SaleCancellationCard from "@/components/report/SaleCancellationCard";
  
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: "Sales Cancellation",
    },
  
    components: {
       SaleCancellationCard
    },
  
    data() {
      return {
      };
    },
  };
  </script>