<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-row>
          <v-col cols="12" lg="6" md="6"
            ><v-card-title
              >Live Sales <span>({{ dateString }})</span></v-card-title
            ></v-col
          >
          <v-col cols="9" lg="5" md="5">
            <v-select
              class="mt-3 ml-2"
              v-model="selectBranch"
              :items="branchList"
              item-text="name"
              item-value="branch_id"
              outlined
              dense
            ></v-select
          ></v-col>
          <v-col cols="3" lg="1" md="1">
            <!-- <v-btn icon :loading="loading"  @click="handleRefresh" class="mt-3" >
              <template v-slot:default>
                <v-icon v-if="!loading">mdi-refresh</v-icon>
              </template>
            </v-btn> -->

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mt-3"
                  icon
                  :loading="loading"
                  @click="handleRefresh"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-if="!loading">mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </base-card>
    </v-col>
    <v-col cols="12" lg="3" md="6">
      <price-card
        price-title="Total Bills"
        :amount="totalBill"
        color-icon="blue-grey--text"
        card-icon="mdi-receipt-text"
        chip-color="success lighten-5"
        :dataLoading="dataLoading"
      />
    </v-col>
    <v-col cols="12" lg="3" md="6">
      <price-card
        price-title="Total Sales(MYR)"
        :amount="totalSales"
        card-icon="mdi-cash"
        color-icon="blue-grey--text"
        chip-color="success lighten-5"
        :dataLoading="dataLoading"
      />
    </v-col>
    <v-col cols="12" lg="3" md="6">
      <price-card
        price-title="Total Refund Bill"
        :amount="totalRefundBill"
        card-icon="mdi-receipt-text-minus"
        color-icon="blue-grey--text"
        chip-color="success lighten-5"
        :dataLoading="dataLoading"
      />
    </v-col>
    <v-col cols="12" lg="3" md="6">
      <price-card
        price-title="Total Refund"
        :amount="totalRefundSales"
        card-icon="mdi-cash-refund"
        color-icon="blue-grey--text"
        chip-color="success lighten-5"
        :dataLoading="dataLoading"
      />
    </v-col>
    <v-col cols="12" lg="3" md="6">
      <price-card
        price-title="Total Discount"
        :amount="totalDiscount"
        card-icon="mdi-sale"
        color-icon="blue-grey--text"
        chip-color="success lighten-5"
        :dataLoading="dataLoading"
      />
    </v-col>
    <v-col cols="12" lg="3" md="6">
      <price-card
        price-title="Total Cancelled Item"
        :amount="totalCancelItem"
        card-icon="mdi-archive-cancel"
        color-icon="blue-grey--text"
        chip-color="success lighten-5"
        :dataLoading="dataLoading"
      />
    </v-col>
    <v-col class="d-md-none d-lg-flex"></v-col>
    <v-col cols="12" md="6">
      <v-card elevation="0" height="300px">
        <div class="d-flex align-center justify-space-between pr-3">
          <v-card-title>Payment Overview</v-card-title>
        </div>
        <v-card-text class="scrollable-content">
          <div class="text-center ma-12" v-if="dataLoading">
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
          <div class="text-center ma-12" v-if="!allPayment && !dataLoading">
            <span>{{ emptyText }}</span>
          </div>

          <v-list subheader v-else-if="allPayment">
            <v-list-item v-for="item in allPayment" :key="item.name">
              <v-list-item-content>
                <v-list-item-title v-text="item.name" />
              </v-list-item-content>

              <v-list-item-action-text>
                <h6 class="text-success">RM {{ item.total.toFixed(2) }}</h6>
              </v-list-item-action-text>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card elevation="0" height="300px">
        <div class="d-flex align-center justify-space-between pr-3">
          <v-card-title>Charges Overview</v-card-title>
        </div>
        <v-card-text class="scrollable-content">
          <div class="text-center ma-12" v-if="dataLoading">
            <v-progress-circular indeterminate></v-progress-circular>
          </div>

          <div class="text-center ma-12" v-if="!allTax && !dataLoading">
            <span>{{ emptyText }}</span>
          </div>

          <v-list subheader v-else-if="allTax">
            <v-list-item v-for="item in allTax" :key="item.tax_name">
              <v-list-item-content>
                <v-list-item-title v-text="item.tax_name" />
              </v-list-item-content>

              <v-list-item-action-text>
                <h6 class="text-success">RM {{ item.amount.toFixed(2) }}</h6>
              </v-list-item-action-text>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
      <script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import TestCard from "@/components/card/TestCard";
import PriceCard from "@/components/card/PriceCard";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Sales",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Sales",
  },
  components: {
    "test-card": TestCard,
    "price-card": PriceCard,
    VueHtml2pdf,
  },
  data() {
    return {
      domain: BASEURL,
      company_id: "",
      date: [],
      emptyText: "No Records",
      branchList: [],
      selectBranch: "",
      totalBill: "",
      totalRefundBill: "",
      totalSales: "",
      totalRefundSales: "",
      totalDiscount: "",
      totalCancelItem: "",
      allPayment: [],
      allTax: [],
      loading: false,
      dataLoading: true,
    };
  },
  computed: {
    dateString() {
      var moment = require("moment");
      return this.date.length > 1
        ? this.date[0] != this.date[1]
          ? moment(this.date[1]).isBefore(this.date[0])
            ? this.date[1] + "~" + this.date[0]
            : this.date[0] + "~" + this.date[1]
          : this.date[0]
        : this.date[0];
    },
  },
  watch: {
    selectBranch() {
      this.dataLoading = true;
      this.totalBill = "";
      this.totalSales = "";
      this.totalCancelItem = "";
      this.totalDiscount = "";
      this.totalRefundBill = "";
      this.totalRefundSales = "";
      this.allPayment = "";
      this.allTax = "";
      this.getAllLiveSalesInfo();
    },
  },
  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getCompanyBranch();
  },
  methods: {
    getAllLiveSalesInfo() {
      const params = new URLSearchParams();
      params.append("dashboard", "a");
      params.append("branch_id", this.selectBranch);

      axios({
        method: "post",
        url: this.domain + "/dashboard/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.totalBill =
              response.data.total_bill == null ||
              response.data.total_bill == "0"
                ? "0"
                : response.data.total_bill.toString();
            this.totalSales =
              response.data.total_sales == null ||
              response.data.total_sales == "0"
                ? "0.00"
                : response.data.total_sales.toFixed(2).toString();
            this.totalCancelItem =
              response.data.total_cancel_item == null ||
              response.data.total_cancel_item == "0"
                ? "0"
                : response.data.total_cancel_item.toString();
            this.totalDiscount =
              response.data.total_discount == null ||
              response.data.total_discount == "0"
                ? "0.00"
                : response.data.total_discount.toFixed(2).toString();
            this.totalRefundBill =
              response.data.total_refund_bill == null ||
              response.data.total_refund_bill == "0"
                ? "0"
                : response.data.total_refund_bill.toString();
            this.totalRefundSales =
              response.data.total_refund_sales == null ||
              response.data.total_refund_sales == "0"
                ? "0.00"
                : response.data.total_refund_sales.toFixed(2).toString();
            this.allPayment = response.data.all_payment;
            this.allTax = response.data.all_tax;
            this.dataLoading = false;
          } else {
            this.totalBill = "0";
            this.totalSales = "0.00";
            this.totalCancelItem = "0";
            this.totalDiscount = "0.00";
            this.totalRefundBill = "0";
            this.totalRefundSales = "0.00";
            this.allPayment = "";
            this.allTax = "";
            this.dataLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleRefresh() {
      this.dataLoading = true;
      this.totalBill = "";
      this.totalSales = "";
      this.totalCancelItem = "";
      this.totalDiscount = "";
      this.totalRefundBill = "";
      this.totalRefundSales = "";
      this.allPayment = "";
      this.allTax = "";

      this.getAllLiveSalesInfo();
      this.loading = true;
      setTimeout(() => {
        this.loading = false; // Stop loading after 10 seconds
      }, 15000);
    },

    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranchWithLiveData", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var moment = require("moment");
            this.branchList = response.data.branch;
            this.selectBranch = this.branchList[0].branch_id;
            this.date.push(moment().format("YYYY-MM-DD"));
            this.getAllLiveSalesInfo();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.scrollable-content {
  max-height: 200px; /* Adjust the height of the scrollable area as needed */
  overflow-y: auto;
}
</style>
      
      
      