<template>
    <v-row>
      <v-col cols="12">
          <sale-refund-card> </sale-refund-card> 
      </v-col>
    </v-row>
  </template>
  <script>
  import SaleRefundCard from "@/components/report/SaleRefundCard";
  
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: "Refund",
    },
  
    components: {
        SaleRefundCard
    },
  
    data() {
      return {
      };
    },
  };
  </script>